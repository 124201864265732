import { Col, Row } from "react-bootstrap";
import { IViewDevicesWithHighestPowerConsumptionPowerConsumerView } from "../../oversight-core/dtos/response-dtos/view-devices-with-highest-power-consumption-response-dto";
import { EConnectionStatus } from "../../oversight-core/enums/connection-status";
import ActiveInactiveIndicator from "../../oversight-core/ui-elements/active-inactive-indicator/active-inactive-indicator";
import MaterialIcon from "../../oversight-core/ui-elements/material-icon/material-icon";
import SpinnerModal from "../../oversight-core/ui-elements/spinner/spinner";
import findIcon from "../../oversight-core/utils/findIcon";
import Helper from "../../oversight-core/utils/helpers";
import styles from "./highest-power-usage-device-card.module.scss";

interface IProps {
  powerConsumerView: IViewDevicesWithHighestPowerConsumptionPowerConsumerView;
}

function HighestPowerUsageDeviceCard(props: IProps) {
  const { powerConsumerView } = props;

  return (
    <Row
      className={`${styles.highestPowerUsageDeviceCardContainer} align-items-center justify-content-between p-3`}
    >
      <Col className="col-12 col-sm-5">
        <Row className="align-items-center">
          <Col
            className={`${powerConsumerView.deviceType} p-3 rounded col-auto`}
          >
            <MaterialIcon icon={findIcon(powerConsumerView.deviceType)} />
          </Col>
          <Col>
            <Row>
              <Col className="font-size-14 font-weight-500 text-dark">
                {powerConsumerView.name}
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col className="col-auto font-size-12 text-light font-weight-400 pe-0">
                {powerConsumerView.powerUsageInWatt}W
              </Col>
              <Col className="ps-1">
                <ActiveInactiveIndicator
                  isActive={
                    powerConsumerView.smartDevices.map(
                      (sd) =>
                        sd.deviceConnectionState === EConnectionStatus.CONNECTED
                    )
                      ? true
                      : false
                  }
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col className="col-6 col-sm-5 mt-2 mt-sm-0 ps-1 ps-sm-0">
        {powerConsumerView.smartDevices.map((sd, index) => {
          return (
            <Row key={index}>
              <Col className="text-light font-weight-500 font-size-14">
                {sd.name}
              </Col>
            </Row>
          );
        })}
      </Col>
      <Col className="col-auto col-sm-2 ps-0 mt-2 mt-sm-0">
        <Row className="align-items-center">
          <Col className="col-auto pe-0">
            <SpinnerModal
              show={true}
              withOverlay={false}
              size="sm"
              color="red"
              speedMultiplier={0.6}
            />
          </Col>
          <Col className="col-auto font-size-14 text-dark font-weight-500 pe-0">
            {Helper.roundTo2(powerConsumerView.livePowerUsage)}W
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default HighestPowerUsageDeviceCard;
