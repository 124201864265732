import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setToken } from "../../../../redux/features/auth/auth-slice";
import { AppRoute } from "../../interfaces/app-routes";
import LoginLayout from "../../shared-components/login-layout/login-layout";
import ResetPasswordForm from "./reset-password-form/reset-password-form";

const ResetPassword = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleBack = () => {
    localStorage.removeItem("token");
    dispatch(setToken(""));
    navigate(AppRoute.LOGIN);
  };

  return (
    <LoginLayout
      title="Create New Password"
      subTitle="Enter your new password below. Your new password must be different from the previous passwords."
      showBackButton={true}
      onBack={() => {
        state && state?.isChange ? navigate(-1) : handleBack();
      }}
    >
      <ResetPasswordForm />
    </LoginLayout>
  );
};

export default ResetPassword;
