import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import ReactGA from "react-ga4";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { useLoginMutation } from "../../../redux/api/auth/authAPI";
import AuthDTO from "../../../shared/oversight-core/dtos/request-dtos/auth-dto";
import { AppRoute } from "../../../shared/oversight-core/interfaces/app-routes";
import { IHttpError } from "../../../shared/oversight-core/interfaces/http-errror";
import AppButton from "../../../shared/oversight-core/ui-elements/buttons/app-button/app-button";
import CheckBox from "../../../shared/oversight-core/ui-elements/check-box/check-box";
import AppInput from "../../../shared/oversight-core/ui-elements/input/app-input";
import isMobileNumberVerified from "../../../shared/oversight-core/utils/isMobileNumberVerified";
import { showErrorMessage } from "../../../shared/oversight-core/utils/toast";

const LoginForm = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const [errorStyle, setErrorStyle] = useState(false);
  const navigate = useNavigate();
  const [login, { isError, error, isLoading }] = useLoginMutation();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<AuthDTO>();

  const onSubmit = (data: AuthDTO) => {
    login({
      ...data,
      rememberMe,
    })
      .unwrap()
      .then(() => {
        ReactGA.event({
          category: "Successful",
          action: "Login",
        });
      })
      .catch((error: IHttpError) => {
        if (isMobileNumberVerified(error)) {
          navigate(AppRoute.VERIFY_MOBILE_NUMBER);
        } else {
          setErrorStyle(true);
          showErrorMessage(error.message || "Something went wrong");
        }
      });
  };

  useEffect(() => {
    if (Object.keys(errors).length === 0) {
      setErrorStyle(false);
    } else {
      setErrorStyle(true);
    }
  }, [errors]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {isError && error && "data" in error && (
        <div className="text-center error mt-2">
          Sorry, but the password or username you entered is incorrect. Please
          try again.
        </div>
      )}
      <div className="mt-5">
        <AppInput
          className="mt-4"
          name="username"
          placeholder={"Enter your username or email"}
          label={"Username or Email"}
          register={register("username", {
            required: "Username or Email is required",
          })}
          errors={errors}
          isError={isError}
        />
        <AppInput
          className="mt-4"
          name="password"
          icon={!showPassword ? "visibility_off" : "visibility"}
          placeholder="Enter your password"
          onIconClick={() => setShowPassword(!showPassword)}
          label="Password"
          type={showPassword ? "text" : "password"}
          register={register("password", {
            required: "Password is required",
          })}
          errors={errors}
          isError={isError}
        />
      </div>
      <Row className="mt-4">
        <Col>
          <CheckBox
            label="Remember me"
            checked={rememberMe}
            onChange={(checked) => setRememberMe(checked)}
          />
        </Col>
        <Col className="text-end">
          <Link to={AppRoute.FORGOT_PASSWORD}>Forgot password</Link>
        </Col>
      </Row>
      <AppButton
        text="Sign in"
        className={`mt-4 ${errorStyle && `manual-border-shadow`}`}
        variant="blue"
        isLoading={isLoading}
      />
      <Row className="justify-content-center mt-5">
        <Col className="text-center text-light">
          Use Access Code <Link to={AppRoute.ACCESS_BREEZE_LINK}>Here</Link>
        </Col>
      </Row>
    </form>
  );
};

export default LoginForm;
