import { useEffect, useState } from "react";
import Avatar from "react-avatar";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useLazyGetExtendedProfileQuery } from "../../../../redux/api/user/userAPI";
import { selectProfile } from "../../../../redux/features/user/user-slice";
import { EProviders } from "../../enums/providers";
import { UserRole } from "../../enums/user-roles";
import { AppRoute } from "../../interfaces/app-routes";
import IExtendedUserProfile, {
  IAccountNumbersResponse,
  IExtendedUserProfileData,
  ISpaceClusterViews,
} from "../../interfaces/extendedUserProfile";
import { IHttpError } from "../../interfaces/http-errror";
import AddUpdateAccountNumberModal from "../../shared-pages/user-profile/components/modals/add-update-account-number-modal/add-update-account-number-modal";
import UserProfileData from "../../shared-pages/user-profile/components/user-profile-data/user-profile-data";
import AppButton from "../../ui-elements/buttons/app-button/app-button";
import IconButton from "../../ui-elements/buttons/icon-button/icon-button";
import DataTable from "../../ui-elements/data-table/data-table";
import MaterialIcon from "../../ui-elements/material-icon/material-icon";
import SpinnerModal from "../../ui-elements/spinner/spinner";
import isMobileNumberVerified from "../../utils/isMobileNumberVerified";
import { showErrorMessage } from "../../utils/toast";
import RemoveAccountNumberModal from "./components/modals/remove-account-number-modal/remove-account-number-modal";
import UserProfileDataEdit from "./components/user-profile-data-edit/user-profile-data-edit";
import styles from "./user-profile.module.scss";

const defaultAccountNumberData: IAccountNumbersResponse = {
  label: "",
  tariffCode: "",
  accountNumber: "",
};

const defaultProfile: IExtendedUserProfileData = {
  id: "",
  firstName: "",
  lastName: "",
  username: "",
  nic: "",
  email: "",
  isEmailVerified: false,
  contactNumber: "",
  isContactNumberVerified: false,
  spaceClusters: [],
  serviceProviderShortName: "",
};

const UserProfile = () => {
  const navigate = useNavigate();
  const profile = useSelector(selectProfile);
  const [billingAccountData, setBillingAccountData] =
    useState<IAccountNumbersResponse>({ ...defaultAccountNumberData });
  const [userProfileData, setUserProfileData] =
    useState<IExtendedUserProfileData>({ ...defaultProfile });

  const [showAddUpdateAccountNumberModal, setShowAddUpdateAccountNumberModal] =
    useState(false);
  const [showRemoveAccountNumberModal, setShowRemoveAccountNumberModal] =
    useState(false);
  const [userBillingAccountNumberLength, setUserBillingAccountNumberLength] =
    useState(false);

  const [triggerGetExtendedProfile, { isFetching: extendProfileDataFetching }] =
    useLazyGetExtendedProfileQuery();

  const getExtendedProfile = () => {
    triggerGetExtendedProfile()
      .unwrap()
      .then((response: IExtendedUserProfile) => {
        const userProfile = { ...response.userProfileView };
        let spaceClusterViews = userProfile.spaceClusters
          ? [...userProfile.spaceClusters]
          : [];
        spaceClusterViews = spaceClusterViews.sort(
          (a: ISpaceClusterViews, b: ISpaceClusterViews) => {
            const labelA = a.serviceProviderAccount.label.toLowerCase();
            const labelB = b.serviceProviderAccount.label.toLowerCase();
            if (labelA < labelB) {
              return -1;
            }
            if (labelA > labelB) {
              return 1;
            }
            return 0;
          }
        );
        userProfile.spaceClusters = spaceClusterViews;
        setUserProfileData(userProfile);
        setUserBillingAccountNumberLength(userProfile.spaceClusters.length > 0);
      })
      .catch((error: IHttpError) => {
        if (isMobileNumberVerified(error)) {
          navigate(AppRoute.VERIFY_MOBILE_NUMBER);
        } else {
          showErrorMessage(error.message || "Something went wrong");
        }
      });
  };

  useEffect(() => {
    getExtendedProfile();
  }, []);

  const accountNumberEditClick = (accountData: IAccountNumbersResponse) => {
    setBillingAccountData(accountData);
    setShowAddUpdateAccountNumberModal(true);
  };

  const accountNumberDeleteClick = (accountData: IAccountNumbersResponse) => {
    setBillingAccountData(accountData);
    setShowRemoveAccountNumberModal(true);
  };

  return (
    <>
      <div className="container-white">
        <Row>
          <Col xl={6}>
            <Row
              className={`${styles.userProfile} h-100 align-items-center justify-content-center gap-3 p-4 mx-0`}
            >
              <Col className="col-auto me-2">
                <Avatar
                  name={`${userProfileData.firstName} ${userProfileData.lastName}`}
                  size="150"
                  round={true}
                />
              </Col>
              <Col className="col-md-7  col-12">
                <UserProfileData
                  firstName={userProfileData.firstName}
                  lastName={userProfileData.lastName}
                  NIC={userProfileData.nic}
                  username={userProfileData.username}
                  getExtendedProfile={getExtendedProfile}
                />
              </Col>
            </Row>
          </Col>
          <Col xl={6}>
            <Row className="mt-4 mt-xl-0">
              <Col>
                {profile && (
                  <UserProfileDataEdit
                    title="Mobile Number"
                    text={userProfileData.contactNumber}
                    hasIcon={true}
                    isVerified={userProfileData.isContactNumberVerified}
                    handleEdit={() => {
                      navigate(AppRoute.CHANGE_MOBILE_NUMBER);
                    }}
                    showEditIcon={
                      profile.serviceProvider !== EProviders.LECO &&
                      profile.role === UserRole.ROLE_USER
                    }
                  />
                )}
              </Col>
            </Row>
            <Row className="mt-4">
              <Col>
                {profile && (
                  <UserProfileDataEdit
                    title="Email"
                    text={userProfileData.email}
                    hasIcon={true}
                    isVerified={userProfileData.isEmailVerified}
                    handleEdit={() => {
                      navigate(AppRoute.CHANGE_EMAIL);
                    }}
                    showEditIcon={
                      profile.serviceProvider !== EProviders.LECO &&
                      profile.role === UserRole.ROLE_USER
                    }
                  />
                )}
              </Col>
            </Row>
            <Row className="mt-4">
              <Col>
                {profile && (
                  <UserProfileDataEdit
                    title="Password"
                    text="* * * * * * * * *"
                    handleEdit={() => {
                      navigate(AppRoute.CHANGE_PASSWORD, {
                        state: { isChange: true },
                      });
                    }}
                    showEditIcon={
                      profile.serviceProvider !== EProviders.LECO &&
                      profile.role === UserRole.ROLE_USER
                    }
                  />
                )}
              </Col>
            </Row>
          </Col>
        </Row>
        {userProfileData.spaceClusters.length > 0 && (
          <>
            {profile && profile.role === UserRole.ROLE_USER && (
              <Row className="mt-4">
                <DataTable
                  headers={[
                    { id: 1, columnName: "Label" },
                    {
                      id: 2,
                      columnName: "Billing Account Number",
                    },
                    {
                      id: 3,
                      columnName: "Tariff Code",
                    },
                    {
                      id: 4,
                      columnName: "Billing Space",
                    },
                    { id: 5, columnName: "" },
                    { id: 6, columnName: "" },
                    {
                      id: 7,
                      columnName:
                        profile &&
                        profile.serviceProvider !== EProviders.LECO ? (
                          <>
                            <AppButton
                              text="Add Bill Number"
                              size="medium"
                              style={{ width: "180px", float: "right" }}
                              className="me-2 d-none d-sm-block"
                              onClick={() => {
                                setBillingAccountData({
                                  ...defaultAccountNumberData,
                                });
                                setShowAddUpdateAccountNumberModal(true);
                              }}
                              id="add-bill-number"
                            />
                            <IconButton
                              icon="add"
                              className="d-block d-sm-none"
                              onClick={() => {
                                setBillingAccountData({
                                  ...defaultAccountNumberData,
                                });
                                setShowAddUpdateAccountNumberModal(true);
                              }}
                            />
                          </>
                        ) : (
                          <></>
                        ),
                    },
                  ]}
                  data={
                    userProfileData.spaceClusters
                      ? userProfileData.spaceClusters.map(
                          (spaceCluster, spaceClusterIndex) => {
                            return {
                              data: [
                                <>
                                  {spaceCluster.serviceProviderAccount.label}
                                </>,
                                <>
                                  {
                                    spaceCluster.serviceProviderAccount
                                      .accountNumber
                                  }
                                </>,
                                <>
                                  {
                                    spaceCluster.serviceProviderAccount
                                      .tariffCode
                                  }
                                </>,
                                <>{spaceCluster.label || "-"}</>,
                                profile &&
                                profile.serviceProvider !== EProviders.LECO ? (
                                  <MaterialIcon
                                    key={`${billingAccountData.accountNumber}${spaceClusterIndex}`}
                                    icon="border_color"
                                    color="#69768B"
                                    className="cursor-pointer"
                                    onClick={() => {
                                      accountNumberEditClick(
                                        spaceCluster.serviceProviderAccount
                                      );
                                    }}
                                    size={14}
                                  />
                                ) : (
                                  <></>
                                ),
                                profile &&
                                profile.serviceProvider !== EProviders.LECO ? (
                                  <MaterialIcon
                                    key={`${spaceClusterIndex}${billingAccountData.accountNumber}`}
                                    icon="delete"
                                    color="#69768B"
                                    className="cursor-pointer"
                                    onClick={() => {
                                      accountNumberDeleteClick(
                                        spaceCluster.serviceProviderAccount
                                      );
                                    }}
                                    size={14}
                                  />
                                ) : (
                                  <></>
                                ),
                                <></>,
                              ],
                            };
                          }
                        )
                      : []
                  }
                />
              </Row>
            )}
          </>
        )}
        <AddUpdateAccountNumberModal
          closeAddUpdateAccountNumberModal={() => {
            setShowAddUpdateAccountNumberModal(false);
            setBillingAccountData({ ...defaultAccountNumberData });
          }}
          onCancel={() => {
            setShowAddUpdateAccountNumberModal(false);
            setBillingAccountData({ ...defaultAccountNumberData });
          }}
          onClose={() => {
            setShowAddUpdateAccountNumberModal(false);
            setBillingAccountData({ ...defaultAccountNumberData });
          }}
          show={showAddUpdateAccountNumberModal}
          accountData={billingAccountData}
          getAccountNumbers={getExtendedProfile}
        />
        <RemoveAccountNumberModal
          show={showRemoveAccountNumberModal}
          onConfirm={() => {
            setShowRemoveAccountNumberModal(false);
          }}
          onCancel={() => {
            setShowRemoveAccountNumberModal(false);
          }}
          onClose={() => {
            setShowRemoveAccountNumberModal(false);
          }}
          accountNumber={billingAccountData.accountNumber}
          getAccountNumbers={getExtendedProfile}
        />
        <SpinnerModal show={extendProfileDataFetching} />
      </div>
      {!userBillingAccountNumberLength &&
        profile &&
        profile.role === UserRole.ROLE_USER && (
          <div className="container-dash mt-4">
            <Row>
              <Col className="text-center text-light font-size-12">
                There are no available Billing Account Numbers.{" "}
                <span
                  onClick={() => setShowAddUpdateAccountNumberModal(true)}
                  className={`${styles.txt} cursor-pointer`}
                >
                  Add Billing Account Numbers
                </span>
              </Col>
            </Row>
          </div>
        )}
    </>
  );
};

export default UserProfile;
